<template>
  <div id="Logo3D"><div id="p5canvas"></div></div>
</template>

<script>
export default {
  name: "Logo3D",
  props: {},
  mounted() {
    const script = function (p5) {
      /* eslint-disable */

      let p5img;

      let z;
      // NOTE: Set up is here
      p5.setup = () => {
        p5.createCanvas(200, 200, p5.WEBGL);

        // p5img = p5.loadImage(
        //   "https://matt-stent-testing.ams3.cdn.digitaloceanspaces.com/favicon.png"
        // );
        p5img = p5.loadImage(
          " https://matt-stent-testing.ams3.cdn.digitaloceanspaces.com/favicon_grey.png"
        );

        z = 255;
      };

      p5.draw = () => {
        p5.orbitControl(1, 1, 0);
        p5.background(255, 0);
        p5.noStroke();
        p5.texture(p5img);
        p5.box(100);
        p5.fill(255, z);
        p5.box(101);
        z = z - 4;
      };
    };
    // NOTE: Use p5 as an instance mode
    const P5 = require("p5");
    new P5(script, "p5canvas");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#Logo3D {
  #p5canvas {
  }
}
</style>
