<template>
  <div id="Section0">
    <div class="row">
      <div class="col-12 col-sm-10 text-center">
        Hi! I’m Matt Stent, a freelance Creative Technologist and Software
        Developer. I love solving problems with innovative uses of technology.
        Here are some things I can help you with:
      </div>
    </div>
    <div class="row mt-5">
      <div
        class="col-6 offset-3 col-sm-4 offset-md-0 col-md-2 image_container d-flex align-self-center"
        id="image1"
      >
        <img src="~@/assets/micro.png" />
      </div>
      <div
        class="col-12 col-sm-10 col-md-8 text-center text-md-start d-flex align-self-center"
      >
        Using systems such as Arduino, ESP32, RP2040, etc., I can develop
        products that take inputs from a variety of places (sensors, buttons,
        network, serial, semaphore smoke signals, you name it!) to trigger other
        actions.
      </div>
    </div>

    <div class="row mt-4">
      <div
        class="col-12 col-sm-10 col-md-8 text-center text-md-end order-last order-md-first d-flex align-self-center"
      >
        I can create engaging interactive applications both for on-site
        installation and your audience's own devices. Games, Quizzes, and
        Virtual Try-ons are just some of the applications I have developed for
        clients, and I'd love to hear your ideas!
      </div>
      <div
        class="col-6 offset-3 col-sm-4 offset-md-0 col-md-2 image_container order-md-last order-first d-flex align-self-center"
        id="image2"
      >
        <img src="~@/assets/app.png" />
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="col-6 offset-3 col-sm-4 offset-md-0 col-md-2 image_container d-flex align-self-center"
        id="image3"
      >
        <img src="~@/assets/led.png" />
      </div>
      <div
        class="col-12 col-sm-10 col-md-8 text-center text-md-start d-flex align-self-center"
      >
        From simple strips to arrays of individually addressable RGB lights, I
        can help you with exciting and colourful LED presentations. And it can
        all be tied together with an interactive application for an engaging and
        immersive user experience.
      </div>
    </div>

    <div class="row mt-4">
      <div class="text-center col-12 col-sm-10">
        <a href="#" @click="$emit('contact')">Contact me now</a> if you think
        there’s something I can do for you!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section0Component",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#Section0 {
  a {
    color: black;
  }
  .image_container img {
    width: 100%;
    height: auto;
  }

  // }
  // #image1 {
  //   background-image: url("~@/assets/micro.png");
  // }
  // #image2 {
  //   background-image: url("~@/assets/led.png");
  // }
  // #image3 {
  //   background-image: url("~@/assets/app.png");
  // }
}
</style>
