<template>
  <div id="Section2">
    <div v-if="showForm">
      Excellent Decision. To get in contact, either fill out the form below or
      email me at
      <a class="weblink" href="mailto:hello@mattstent.co.uk"
        >hello@mattstent.co.uk</a
      >
      and I'll get back to you soon. Cheers!
      <div>
        <div class="my-3 mx-2">
          <label for="enquiryEmail" class="form-label"
            >Your Email Address:</label
          >
          <input
            type="email"
            class="form-control"
            id="enquiryEmail"
            placeholder=""
          />
        </div>
        <div class="mb-3 mx-2">
          <label for="enquiryMessage" class="form-label">Message:</label>
          <textarea
            class="form-control"
            id="enquiryMessage"
            rows="4"
          ></textarea>
        </div>
      </div>
      <div class="col-12 text-center">
        <a class="btn btn-lg btn-success mx-auto" @click="sendEnquiry">SEND!</a>
      </div>
    </div>
    <div v-else class="text-center">
      Thanks - your message has been sent and I'll be in touch soon.
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Section2Component",
  components: {},
  data() {
    return {
      showForm: true,
    };
  },
  mounted() {},
  methods: {
    sendEnquiry() {
      let mreq = {};
      mreq.from = document.getElementById("enquiryEmail").value;
      mreq.message = document.getElementById("enquiryMessage").value;
      axios.post(
        "https://faas-ams3-2a2df116.doserverless.co/api/v1/web/fn-50ada61e-3542-454d-847d-5e8abd1b871b/enquiry/email",
        mreq
      );

      setTimeout(() => {
        this.showForm = false;
      }, 500);
      setTimeout(() => {
        this.showForm = true;
      }, 15000);
    },
  },
};
</script>

<style lang="scss">
#Section2 {
  a.weblink {
    color: black;
  }
  .form-control:focus {
    box-shadow: 0 0 5px #cccccc;
    border: #cccccc 1px solid;
  }
}
</style>
