<template>
  <div id="Section3">
    If you've been given a code to view demo content etc. you can enter it here
    and click 'Go' to view it:

    <div class="my-3 mx-2">
      <label for="demoCode" class="form-label">Code:</label>
      <input
        type="text"
        class="form-control"
        id="demoCode"
        maxlength="14"
        minlength="14"
        placeholder="xxxx-xxxx-xxxx"
        pattern="/^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/"
      />
    </div>

    <div class="mx-auto col-2">
      <a class="btn btn-lg btn-success mx-auto" @click="demoCode">Go</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section3Component",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    demoCode() {},
  },
};
</script>

<style lang="scss">
#Section3 {
  text-align: center;
  .form-control {
    text-align: center;
  }
  .form-control:focus {
    box-shadow: 0 0 5px #cccccc;
    border: #cccccc 1px solid;
  }
}
</style>
