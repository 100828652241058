<template>
  <div id="portfolio">
    <iframe
      src="https://docs.google.com/presentation/d/e/2PACX-1vSPhHb6E7_gfw5sTowWlGsO42SKo44ZpkW1ybdGyaNoth11PCWZbQULhpIOZZkDV9jsa0kfSlaDoqNv/embed?start=true&loop=true&delayms=60000"
      frameborder="0"
      id="slides_embed"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "HomeView",

  metaInfo: {
    meta: [
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
    ],
  },

  mounted() {
    !(function (t, e) {
      var o, n, p, r;
      e.__SV ||
        ((window.posthog = e),
        (e._i = []),
        (e.init = function (i, s, a) {
          function g(t, e) {
            var o = e.split(".");
            2 == o.length && ((t = t[o[0]]), (e = o[1])),
              (t[e] = function () {
                t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              });
          }
          ((p = t.createElement("script")).type = "text/javascript"),
            (p.async = !0),
            (p.src = s.api_host + "/static/array.js"),
            (r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(
              p,
              r
            );
          var u = e;
          for (
            void 0 !== a ? (u = e[a] = []) : (a = "posthog"),
              u.people = u.people || [],
              u.toString = function (t) {
                var e = "posthog";
                return (
                  "posthog" !== a && (e += "." + a), t || (e += " (stub)"), e
                );
              },
              u.people.toString = function () {
                return u.toString(1) + ".people (stub)";
              },
              o =
                "capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(
                  " "
                ),
              n = 0;
            n < o.length;
            n++
          )
            g(u, o[n]);
          e._i.push([i, s, a]);
        }),
        (e.__SV = 1));
    })(document, window.posthog || []);
    // eslint-disable-next-line
    posthog.init("phc_QtxEVH0HRPZcF9ZgxBHR23IIVKXNTkkNvcxuafEbKVN", {
      api_host: "https://eu.posthog.com",
    });
  },
  methods: {},
};
</script>

<style lang="scss">
#portfolio {
  height: 100vh;
  background-color: black;
  #slides_embed {
    width: 90vw;
    height: 90vh;
  }
}
</style>
