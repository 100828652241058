<template>
  <div id="Section1">
    Portfolio Coming Soon - in the meantime,
    <a href="#" @click="$emit('contact')">Contact me now</a> if you think
    there’s something I can do for you!
  </div>
</template>

<script>
export default {
  name: "Section1Component",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#Section1 {
  a {
    color: black;
  }
}
</style>
