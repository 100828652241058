<template>
  <div id="ContentComponent">
    <div class="row d-flex align-items-center">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 text-start">
        <div @click="gotoTOC" class="h1 mt-1 mb-3 text-center pointer">
          {{ activeHeader }}
        </div>

        <div class="col-12 text-center col-sm-12 offset-sm-0">
          <div
            class="row pointer"
            v-if="!showSection0 && TOC"
            @click="section0"
          >
            <span
              class="mb-1 col-12 sectionHeader"
              :class="pulseText == 0 && !statusComplete0 ? 'pulseText' : ''"
              >1)................Introduction </span
            ><span
              class="d-none"
              :class="statusComplete0 ? 'statusComplete' : 'statusIncomplete'"
              >{{ taskText[0] }}</span
            >
          </div>
        </div>
        <div class="col-12 text-center col-sm-12 offset-sm-0">
          <div
            v-if="!showSection1 && TOC"
            @click="section1"
            class="row pointer"
          >
            <span class="mb-1 col-12 sectionHeader"
              >2).......................My Work </span
            ><span
              class="d-none"
              :class="statusComplete1 ? 'statusComplete' : 'statusIncomplete'"
              >{{ taskText[1] }}</span
            >
          </div>
        </div>
        <div class="col-12 text-center col-sm-12 offset-sm-0">
          <div
            v-if="!showSection2 && TOC"
            @click="section2"
            class="row pointer"
          >
            <span class="mb-1 col-12 sectionHeader"
              >3)........................Hire me! </span
            ><span
              class="d-none"
              :class="statusComplete2 ? 'statusComplete' : 'statusIncomplete'"
              >{{ taskText[2] }}</span
            >
          </div>
        </div>
        <div class="col-12 text-center col-sm-12 offset-sm-0">
          <div @click="section3" v-if="!showSection3 && TOC" class="row">
            <span class="h2 mb-1 col-12 sectionHeader pointer"
              >4)...................Client Area</span
            >
            <span class="optionalText d-none">{{ taskText[3] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="contentSection col-12 coll-sm-10 offset-sm-1 col-xl-10 offset-xl-2"
        v-if="showSection0"
      >
        <Section0 @contact="goToContact()" />
      </div>
      <div class="contentSection col-10 offset-1" v-if="showSection1">
        <Section1 @contact="goToContact()" />
      </div>
      <div
        class="contentSection col-sm-10 offset-sm-1 col-md-6 offset-md-3"
        v-show="showSection2"
      >
        <Section2 />
      </div>
      <div
        class="contentSection col-sm-10 offset-sm-1 col-md-6 offset-md-3"
        v-if="showSection3"
      >
        <Section3 />
      </div>
    </div>
    <div @click="gotoTOC" v-if="!TOC" class="pt-4 mb-5">
      <span class="tocClick pointer px-3 py-1">Back to Table of Contents</span>
    </div>
  </div>
</template>

<script>
import Section0 from "@/components/Section0.vue";
import Section1 from "@/components/Section1.vue";
import Section2 from "@/components/Section2.vue";
import Section3 from "@/components/Section3.vue";
export default {
  name: "HeaderComponent",
  components: { Section0, Section1, Section2, Section3 },
  data() {
    return {
      activeHeader: "Table of Contents",
      TOC: true,
      statusComplete0: false,
      statusComplete1: false,
      statusComplete2: false,
      showSection0: false,
      showSection1: false,
      showSection2: false,
      showSection3: false,
      pulseText: null,
      headerClicked: false,
      taskText: ["[incomplete]", "[incomplete]", "[incomplete]", "[optional]"],
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.headerClicked) {
        this.pulseText = 0;
      }
    }, 5000);
  },
  methods: {
    gotoTOC() {
      this.activeHeader = "Table of Contents";
      this.TOC = true;
      this.headerClicked = true;
      this.pulseText = null;
      this.showSection0 = false;
      this.showSection1 = false;
      this.showSection2 = false;
      this.showSection3 = false;
    },
    section0() {
      this.headerClicked = true;
      this.pulseText = null;
      if (!this.showSection0) {
        this.TOC = false;
        this.activeHeader = "Introduction";
        this.statusComplete0 = true;
        this.showSection0 = true;
        this.showSection1 = false;
        this.showSection2 = false;
        this.showSection3 = false;
        this.taskText[0] = "[complete]";
      } else {
        this.gotoTOC();
      }
    },
    section1() {
      this.headerClicked = true;
      this.pulseText = null;
      if (!this.showSection1) {
        this.TOC = false;
        this.activeHeader = "My Work";
        this.statusComplete1 = true;
        this.showSection1 = true;
        this.showSection2 = false;
        this.showSection3 = false;
        this.showSection0 = false;
        this.taskText[1] = "[complete]";
      } else {
        this.gotoTOC();
      }
    },
    section2() {
      this.headerClicked = true;
      this.pulseText = null;
      if (!this.showSection2) {
        this.TOC = false;
        this.activeHeader = "Hire Me!";
        this.statusComplete2 = true;
        this.showSection2 = true;
        this.showSection3 = false;
        this.showSection0 = false;
        this.showSection1 = false;
        this.taskText[2] = "[complete]";
      } else {
        this.gotoTOC();
      }
    },
    section3() {
      this.headerClicked = true;
      this.pulseText = null;
      if (!this.showSection3) {
        this.TOC = false;
        this.activeHeader = "Client Area";
        this.showSection3 = true;
        this.showSection0 = false;
        this.showSection1 = false;
        this.showSection2 = false;
      } else {
        this.gotoTOC();
      }
    },
    goToContact() {
      this.section2();
    },
  },
};
</script>

<style lang="scss">
#ContentComponent {
  font-family: "caprasimo";
  color: rgba(0, 0, 0, 0.75);
  .statusIncomplete {
    color: #ab4646;
  }
  .statusComplete {
    color: #31a859;
  }
  .optionalText {
    color: #3c7ab7;
  }
  .sectionHeader {
    color: rgba(0, 0, 0, 0.75);
    transition: 0.5s;
    font-size: max(2.5vw, 17px);
  }
  .sectionHeader:hover,
  .sectionHeader:active {
    color: #31a859;
  }
  .contentSection {
    // max-height: 52vh;
    // overflow: auto;
    // padding-bottom: 25px;
  }
  .tocClick {
    border: 3px #31a859 dashed;
    color: #31a859;
    border-radius: 50px;
    transition: 0.5s;
  }
  .tocClick:hover,
  .tocClick:focus {
    border: 3px rgba(0, 0, 0, 0.75) dashed;
    color: rgba(0, 0, 0, 0.75);
    border-radius: 50px;
  }
  .pointer {
    cursor: pointer;
  }
  .pulseText {
    animation-name: pulseColor;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
  }
  @keyframes pulseColor {
    from {
      color: rgba(0, 0, 0, 0.75);
    }
    to {
      color: #31a859;
    }
  }
}
</style>
