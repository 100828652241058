<template>
  <div id="HeaderComponent">
    <div class="row d-flex align-items-center">
      <div class="col-12"><Logo3D /></div>
      <div id="headerContainer" class="col-12">
        <div class="row">
          <div class="col-12">
            <span
              v-for="c in ms"
              class="headerSpan pt-2"
              :class="c[2] ? 'primaryFont' : 'secondaryFont'"
              :key="c[0]"
              :style="`max-width:${c[3] * 12.5}px;width:${c[3]}vw`"
              >{{ c[1] }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span
              v-for="c in ct"
              class="headerSpan pt-2 pb-3"
              :class="c[2] ? 'primaryFont' : 'secondaryFont'"
              :key="c[0]"
              :style="`max-width:${c[3] * 12.5}px;width:${c[3]}vw`"
              >{{ c[1] }}</span
            >
          </div>
        </div>
      </div>
      <div class="d-none form-check form-check-reverse form-switch">
        <label class="form-check-label" for="flexSwitchCheckChecked"
          >Audio On (Annoying)</label
        >
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
        />
      </div>
    </div>
    <div class="row">
      <div class="offset-6 col-6"></div>
    </div>
  </div>
</template>

<script>
import Logo3D from "@/components/Logo3D.vue";

export default {
  name: "HeaderComponent",
  components: {
    Logo3D,
  },
  data() {
    return {
      ms: [
        [0, "M", true, 7],
        [1, "a", true, 4],
        [2, "t", true, 4],
        [3, "t", true, 4],
        [4, " ", true, 2],
        [5, "S", true, 5],
        [6, "t", true, 4],
        [7, "e", true, 4],
        [8, "n", true, 5],
        [9, "t", true, 4],
      ],
      ct: [
        [0, "C", true, 5],
        [1, "r", true, 4],
        [2, "e", true, 4],
        [3, "a", true, 4],
        [4, "t", true, 4],
        [5, "i", true, 3],
        [6, "v", true, 5],
        [7, "e", true, 4],
        [8, " ", true, 2],
        [9, "T", true, 5],
        [10, "e", true, 4],
        [11, "c", true, 4],
        [12, "h", true, 5],
        [13, "n", true, 5],
        [14, "o", true, 4],
        [15, "l", true, 3],
        [16, "o", true, 4],
        [17, "g", true, 5],
        [18, "y", true, 5],
      ],
      timeouts: [],
      currentto: 0,
      timeoutNo: 100,
    };
  },
  mounted() {
    this.makeTimeouts();
    this.fontRandomiser();
  },
  methods: {
    makeTimeouts() {
      for (let t = 0; t < this.timeoutNo; t++) {
        let rand = Math.floor(Math.random() * 5) + 5;
        this.timeouts.push(rand);
      }
    },
    fontRandomiser() {
      this.timeouts.forEach((el) => {
        this.currentto += el;
        let letter = Math.floor(Math.random() * this.ct.length);
        setTimeout(() => {
          this.$set(this.ct[letter], 2, false); // Update the "secondaryFont" class flag
          setTimeout(() => {
            this.$set(this.ct[letter], 2, true); // Update the "secondaryFont" class flag
          }, 1000);
        }, this.currentto * 1000);
      });
    },
  },
};
</script>

<style lang="scss">
#HeaderComponent {
  #headerContainer {
    overflow-y: hidden;
  }
  .headerSpan {
    display: inline-block;
    // background-color: red;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.75);
    mix-blend-mode: hard-light;
    background-clip: text;
    font-size: min(7vw, 70px);
    line-height: min(7vw, 50px);
    pointer-events: none;
    user-select: none;
  }
  .primaryFont {
    font-family: "caprasimo";
  }
  .secondaryFont {
    font-family: "handjet";
  }
  .form-check-input {
    background-color: white;
    border-color: black;
    outline: none;
  }
  .form-check-input:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
  .form-check-input:checked {
    background-color: black;
    color: black;
  }
  .form-check-input:not(:checked) ~ .form-check-label::after {
    background-color: green;
  }
}
</style>
