<template>
  <div id="mainView" class="container-xxl">
    <!-- <div class="col-12 col-xxl-10 offset-xxl-1"> -->
    <div>
      <HeaderComponent />
    </div>
    <div><ContentComponent /></div>
    <!-- </div> -->
    <div id="paper"></div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import ContentComponent from "@/components/ContentComponent.vue";

export default {
  name: "HomeView",

  metaInfo: {
    meta: [
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },

      {
        name: "description",
        content:
          "I’m Matt Stent, a freelance Creative Technologist and Software Developer. I love solving problems with innovative uses of technology.",
      },
    ],
  },
  components: {
    HeaderComponent,
    ContentComponent,
  },
  data() {
    return {};
  },
  mounted() {
    !(function (t, e) {
      var o, n, p, r;
      e.__SV ||
        ((window.posthog = e),
        (e._i = []),
        (e.init = function (i, s, a) {
          function g(t, e) {
            var o = e.split(".");
            2 == o.length && ((t = t[o[0]]), (e = o[1])),
              (t[e] = function () {
                t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              });
          }
          ((p = t.createElement("script")).type = "text/javascript"),
            (p.async = !0),
            (p.src = s.api_host + "/static/array.js"),
            (r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(
              p,
              r
            );
          var u = e;
          for (
            void 0 !== a ? (u = e[a] = []) : (a = "posthog"),
              u.people = u.people || [],
              u.toString = function (t) {
                var e = "posthog";
                return (
                  "posthog" !== a && (e += "." + a), t || (e += " (stub)"), e
                );
              },
              u.people.toString = function () {
                return u.toString(1) + ".people (stub)";
              },
              o =
                "capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(
                  " "
                ),
              n = 0;
            n < o.length;
            n++
          )
            g(u, o[n]);
          e._i.push([i, s, a]);
        }),
        (e.__SV = 1));
    })(document, window.posthog || []);
    // eslint-disable-next-line
    posthog.init("phc_QtxEVH0HRPZcF9ZgxBHR23IIVKXNTkkNvcxuafEbKVN", {
      api_host: "https://eu.posthog.com",
    });
  },
  methods: {},
};
</script>

<style lang="scss">
@font-face {
  font-family: "caprasimo";
  src: url("~@/assets/Caprasimo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "handjet";
  src: url("~@/assets/Handjet-Regular.ttf") format("truetype");
}
#mainView {
  font-size: 18px;
  .h1 {
  }
  #paper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-image: url("https://matt-stent-testing.ams3.cdn.digitaloceanspaces.com/paper.jpg");
    background-size: cover;
    mix-blend-mode: multiply;
    opacity: 1;
    pointer-events: none;
  }
}
@import "~bootstrap/dist/css/bootstrap.css";
</style>
